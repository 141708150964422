<template>
  <div class="flex full-centered">
    <AppCard sheet centered>
      <div class="loading-container pt-14 md:pt-0">
        <div class="overlay-container">
          <XeLogo />
          <AppSpinnerBig :loading="true" :inline="true" />
        </div>
      </div>
      <h2 class="type-h2 mb-4 text-center text-primary-text">
        {{ $t('SendMoneyCreating.AdyenRedirectToXeTitle').value }}
      </h2>
      <p
        class="text-center text-tertiary-text"
        v-html="$t('SendMoneyCreating.AdyenRedirectToXeInstructions').value"
      />
    </AppCard>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { useI18nStore, useSendMoneyStore } from '@galileo/stores'
import { AppSpinnerBig, AppCard } from '@oen.web.vue2/ui'
import XeLogo from '@galileo/components/XeLogo/XeLogo'

export default defineComponent({
  components: {
    AppSpinnerBig,
    AppCard,
    XeLogo,
  },
  props: {},
  events: [],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { changeAdyenCheckoutComplete } = useSendMoneyStore()
    const isLoading = ref(true)
    const router = useRouter()
    const channel = new BroadcastChannel('adyen-checkout-complete')

    channel.onmessage = async (event) => {
      if (event.data.closeWindow) {
        channel.postMessage('focus-tab')
        closeWindow()
      }
    }

    onMounted(() => {
      const redirectResult = router.history.current.query['redirectResult']

      changeAdyenCheckoutComplete(true, { redirectResult })
      isLoading.value = false

      //fallback closing the window
      setTimeout(closeWindow, 1000)
    })

    const closeWindow = () => {
      window.close()
    }

    return {
      isLoading,
      $t,
    }
  },
})
</script>

<style scoped>
.full-centered {
  height: 100%;
}

.loading-container {
  @apply flex justify-center mb-6;
}
.overlay-container {
  @apply relative flex justify-center items-center w-13 h-13;
}

.overlay-container > img,
svg {
  @apply absolute;
  top: 5px;
}

.overlay-container > .loading-spinner {
  @apply w-auto;
}

::v-deep .overlay-container > .loading-spinner > .loading-spinner-container > svg {
  width: 5rem;
  height: 5rem;
}
</style>
